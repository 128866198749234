import { z } from 'zod'
import { validateAccessGroupName } from '~/utils/backend/access-groups-api'
import { validateSubscriptionPlanName } from '~/utils/backend/subscription-plans-api'
import { validateFtpUser } from '~/utils/backend/ftp-users-api'
import { validateMailUser } from '~/utils/backend/mail-users-api'

export const DeviceStatusSchema = z.union([
  z.literal('ON'),
  z.literal('OFF'),
  z.literal('ON_ALARM'),
  z.literal('OFF_ALARM'),
])

export const DeviceTagLevelSchema = z.union([
  z.literal('INFO'),
  z.literal('WARN'),
  z.literal('ERROR'),
])

export const DeviceTagSchema = z.object({
  level: DeviceTagLevelSchema,
  message: z.string(),
})

export const AccountDirectLinkedDeviceSchema = z.object({
  hexacode: z.string(),
  name: z.string(),
  dealer: z.string().optional().nullable(),
  subDealer: z.string().optional().nullable(),
  customer: z.string().optional().nullable(),
  type: z.string(),
  status: DeviceStatusSchema,
  deviceReadOnly: z.boolean().optional(),
})

export const AccessGroupLinkedDeviceSchema = z.object({
  deviceReadOnly: z.boolean().optional(),
  status: DeviceStatusSchema,
  hexacode: z.string(),
  name: z.string(),
  dealer: z.string().nullable().optional(),
  subDealer: z.string().nullable().optional(),
  customer: z.string().optional(),
  type: z.string(),
  demoDevice: z.boolean(),
  tags: DeviceTagSchema.array(),
})

export const SoftwareVersionLinkedDeviceSchema = z.object({
  deviceReadOnly: z.boolean().optional(),
  status: DeviceStatusSchema,
  hexacode: z.string(),
  name: z.string(),
  dealer: z.string().nullable(),
  subDealer: z.string().nullable(),
  customer: z.string(),
  type: z.string(),
  host: z.string(),
})

export const ClientLinkedAccessGroupSchema = z.object({
  name: z.string(),
  accountReadOnly: z.boolean().default(false),
  id: z.string(),
})
const DeviceLinkedAccessGroupClientSchema = z.object({
  id: z.string(),
  name: z.string(),
})
export const DeviceLinkedAccessGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  readOnly: z.boolean(),
  clients: DeviceLinkedAccessGroupClientSchema.array(),
})

export const LinkedDeviceSchema = z.union([AccountDirectLinkedDeviceSchema, AccessGroupLinkedDeviceSchema, SoftwareVersionLinkedDeviceSchema])
export const LinkedAccessGroupSchema = z.union([ClientLinkedAccessGroupSchema, DeviceLinkedAccessGroupSchema])

const linkedClient = {
  id: z.string(),
  username: z.string(),
  isLocked: z.boolean().nullable(),
  demoUser: z.boolean(),
  use2FA: z.boolean(),
  domains: z.string().array(),
  roles: z.string().array(),
  linkedRoles: z.string().array(),
}

export const AccessGroupLinkedClientSchema = z.object({
  ...linkedClient,
  accountReadOnly: z.boolean().optional(),
})
export const DeviceDirectLinkedClientSchema = z.object({
  ...linkedClient,
  readOnly: z.boolean(),
})

export const LinkedClientSchema = z.union([AccessGroupLinkedClientSchema, DeviceDirectLinkedClientSchema])

export const AccessGroupSchema = z.object({
  id: z.string().nullable(),
  name: z.string().min(1, { message: 'Name is required' }),
}).superRefine(async (arg, ctx) => {
  if (arg.id) {
    const isValid = await validateAccessGroupName(arg.id, arg.name).then(r => r.data).catch(() => false)
    if (!isValid) {
      ctx.addIssue({
        path: ['name'],
        message: 'Name already exists',
        code: z.ZodIssueCode.custom,
      })
    }
  }
})

const AccessGroupPermissionsSchema = z.object({
  linkedDevices: AccessGroupLinkedDeviceSchema.array(),
  linkedAccounts: AccessGroupLinkedClientSchema.array(),
})

export const AccessGroupDetailsSchema = z.object({
  accessGroup: AccessGroupSchema,
  permissions: AccessGroupPermissionsSchema,
})

export const SubscriptionPlanSchema = z.object({
  id: z.string().nullable(),
  name: z.string({ message: 'Name is required' }).min(1, { message: 'Name is required' }),
  value: z.number({ message: 'Value is required' }).min(0),
  linkedDevices: LinkedDeviceSchema.array(),
  props: z.object({
    disabled: z.boolean(),
  }).optional(),
}).superRefine(async (arg, ctx) => {
  if (arg.linkedDevices.length > 0) {
    const isValid = await validateSubscriptionPlanName(arg.id, arg.name).then(r => r.data).catch(() => false)
    if (!isValid) {
      ctx.addIssue({
        path: ['name'],
        message: 'Name already exists',
        code: z.ZodIssueCode.custom,
      })
    }
  }
})

export const FtpUserSchema = z.object({
  id: z.number().nullable(),
  username: z.string().min(1, { message: 'Username is required' })
    .refine((value) => {
      return value.length >= 5
    }, () => ({ message: 'Username must have at least 5 characters' })),
  password: z.string().optional(),
  homeDirectory: z.string().optional(),
  uid: z.number().optional(),
  gid: z.number().optional(),
  shell: z.string().optional(),
  accessCount: z.number().optional(),
}).superRefine(async (arg, ctx) => {
  if (arg.username != '') {
    const isValid = await validateFtpUser(arg.id, arg.username).then(r => r.data).catch(() => false)
    if (!isValid) {
      ctx.addIssue({
        path: ['username'],
        message: 'Ftp account already exists',
        code: z.ZodIssueCode.custom,
      })
    }
  }
  if (arg.id === null && !arg.password) {
    ctx.addIssue({
      path: ['password'],
      message: 'Password is required',
      code: z.ZodIssueCode.custom,
    })
  }
})

export const MailUserSchema = z.object({
  id: z.number().nullable(),
  domainId: z.number().nullable(),
  password: z.string().nullable()
    .refine(val => val === null || val.length >= 8, {
      message: 'Password must have at least 8 characters',
    }),
  email: z.string().min(5, 'Email must have at least 5 characters'),
  canReceive: z.boolean(),
  isEnabled: z.boolean(),
}).superRefine(async (arg, ctx) => {
  if (arg.email != '') {
    const isValid = await validateMailUser(arg.id, arg.email).then(r => r.data).catch(() => false)
    if (!isValid) {
      ctx.addIssue({
        path: ['username'],
        message: 'Mail account already exists',
        code: z.ZodIssueCode.custom,
      })
    }
  }
})

export const BufferValidationSchema = z.object({
  id: z.string().nullable(),
  hexacode: z.string().min(1, { message: 'Device is required' }),
  pcNumber: z.number({ message: 'Pc number  is required' }),
  head: z.object({
    department: z.number({ message: 'Network number  is required' }),
    subdepartment: z.number({ message: 'Department  is required' }),
    page: z.number({ message: 'Page is required' }),
    line: z.number({ message: 'Line file is required' }),
  }),
  interval: z.number({ message: 'Interval is required' }),
  start: z.number({ message: 'Start is required' }),
  stor: z.boolean().optional(),
})

export const LoginSchema = z.object({
  username: z.string().min(1, { message: 'Username is required' }),
  password: z.string().min(1, { message: 'Password is required' }),
  rememberMe: z.boolean().default(false),
  type: z.string().default('BASIC'),
})

export const SoftwareVersionDataSchema = z.object({
  boot: z.instanceof(File, { message: 'Boot file is required' }),
  content: z.instanceof(File, { message: 'Content file is required' }),
  linkedDevices: LinkedDeviceSchema.array(),
})

const PerSiteSchema = z.object({
  path: z.literal('PER_SITE'),
  formattedHexacode: z.string().min(1, 'Hexacode is required'),
  domain: z.string().min(1, 'Domain is required'),
  currentFile: z.instanceof(File, { message: 'Adf file is required' }),
})

const DefaultsSchema = z.object({
  path: z.literal('DEFAULTS'),
  formattedHexacode: z.string().optional(),
  domain: z.string().min(1, 'Domain is required'),
  currentFile: z.instanceof(File, { message: 'Adf file is required' }),
})

const GlobalSchema = z.object({
  path: z.literal('GLOBAL'),
  formattedHexacode: z.string().optional(),
  domain: z.string().nullable(),
  currentFile: z.instanceof(File, { message: 'Adf file is required' }),
})

export const AdfDetailsSchema = z.discriminatedUnion('path', [
  PerSiteSchema,
  DefaultsSchema,
  GlobalSchema,
])
