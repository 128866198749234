import type { FtpUser, FtpUserPaginationRequest } from '~/types/Account'
import type { TablePaginationResponse } from '~/types/GenericTable'
import { api } from '~/utils/backend'

export function paginateFtpUsers(request: FtpUserPaginationRequest) {
  return api.post<TablePaginationResponse<FtpUser>>('/ftp-accounts/paginate', request)
}

export function deleteFtpUser(id: string) {
  return api.delete<never>('/ftp-accounts/' + id)
}

export function saveFtpUser(account: FtpUser) {
  return api.post<FtpUser>('/ftp-accounts/', account)
}

export function validateFtpUser(id: number | null, username: string) {
  const params = {
    username: username,
    id: id,
  }
  return api.get('/ftp-accounts/username', { params })
}
