import type { MailUser, MailUserPaginationRequest } from '~/types/Account'
import type { TablePaginationResponse } from '~/types/GenericTable'
import { api } from '~/utils/backend'

export function paginateMailUsers(request: MailUserPaginationRequest) {
  return api.post<TablePaginationResponse<MailUser>>('/mail-users/paginate', request)
}

export function saveMailUser(account: MailUser) {
  return api.post<MailUser>('/mail-users/', account)
}

export function deleteMailUser(id: number) {
  return api.delete<never>('/mail-users/' + id)
}

export function validateMailUser(id: number | null, email: string) {
  const params = {
    email: email,
    id: id,
  }

  return api.get('/mail-users/validate/email', { params })
}
