import type { AccessGroup, AccessGroupPermissions, AccessGroupSaveRequest } from '~/types/AccessGroup'
import type { ClientLinkedAccessGroup } from '~/types/Device'
import { api } from '~/utils/backend'

export function findAccessGroups() {
  return api.get<AccessGroup[]>('/access-groups/get')
}

export function deleteAccessGroup(id: string) {
  return api.delete<never>(`/access-groups/delete/${id}`)
}

export function saveAccessGroup(saveRequest: AccessGroupSaveRequest) {
  return api.post<AccessGroup>('/access-groups/save', saveRequest)
}

export function findAccessGroupPermissions(id: string) {
  return api.get<AccessGroupPermissions>('/access-groups/permissions', { params: { id } })
}

export function validateAccessGroupName(id: string, name: string) {
  return api.get('/access-groups/validate/name', {
    params: {
      id: id,
      name: name,
    },
  })
}

export function findAccessGroupsByAccount(id: string) {
  return api.get<ClientLinkedAccessGroup[]>('/access-groups/by-client-id', {
    params: {
      id: id,
    },
  })
}

export function findAccessGroupsByHexacode(hexacode: string) {
  return api.get('/access-groups/by-hexacode', {
    params: {
      hexacode: hexacode,
    },
  })
}
