import type { LinkedDevice, SubscriptionPlan, SubscriptionPlanSaveRequest } from '~/types/Device'
import { api } from '~/utils/backend'

export function findAllSubscriptionPlans() {
  return api.get<SubscriptionPlan[]>('/subscription-plans/')
}

export function validateSubscriptionPlanName(id: string | null, name: string) {
  return api.get<boolean>('/subscription-plans/validate/name', {
    params: {
      id: id,
      name: name,
    },
  })
}

export function saveSubscriptionPlan(subscriptionPlan: SubscriptionPlanSaveRequest) {
  return api.put<SubscriptionPlan>('/subscription-plans/', subscriptionPlan)
}

export function deleteSubscriptionPlan(id: string) {
  return api.delete(<never>`/subscription-plans/${id}`)
}

export function loadSubscriptionPlanLinkedDevices(id: string) {
  return api.get<LinkedDevice[]>(`/subscription-plans/${id}/devices`)
}
